body {
  color: #555;
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;
  background-color: white;
}

#header .logo {
  height: 80px;
  margin-top: 10px;
}

#hero {
  margin-top: 100px;
  color: white;
  background-image: url("/public/hero.jpg");
  background-position: center;
  background-size: 1400px;
  background-repeat: no-repeat;
  background-color: #020733;
  height: 400px;
}

#hero > .opacity {
  height: 400px;
  background-color: rgba(2, 7, 51, 0.5);
}

#hero .hero-title {
  margin-top: 150px;
}

#about-us {
  background-color: #fafafa;
}

#mission {
  background-color: #edb115;
}

#privacy-policies {
  background-color: #f0f0f0;
}

#privacy-policies li {
  margin-bottom: 2px;
}

#privacy-policies li a {
  text-decoration: none;
}

#footer {
  background-color: #333;
  color: #ddd;
}

#footer a {
  color: #ddd;
}

.topic {
  color: white;
  background-color: #466ea2;
  border-radius: 8px 0px;
}

.blue-card {
  color: white;
  background-color: #466ea2;
  border-radius: 16px 0;
  min-height: 200px;
}

.blue-card p {
  line-height: 1.15rem;
}